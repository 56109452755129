import React from "react"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"

import "./RemarkableProducts.scss"

const HeliosInuvioPremium = () => {
    return (
        <section id="remarkableProducts" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedHTMLMessage id="heliosInuvioPremium.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot font-weight-bold">
                            <FormattedHTMLMessage id="heliosInuvioPremium.paragraphs.p1" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot">
                            <FormattedHTMLMessage id="heliosInuvioPremium.paragraphs.p2" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot">
                            <FormattedHTMLMessage id="heliosInuvioPremium.paragraphs.p3" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="page-text margin-bot">
                            <FormattedHTMLMessage id="heliosInuvioPremium.paragraphs.p4" />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default HeliosInuvioPremium